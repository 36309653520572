$font-stack-regular: 'Roboto Condensed', sans-serif;
$font-stack-code: consolas, menlo, 'Courier New', monospace;

$colour-bg1: #01ab8a;
$colour-bg2: #35c6e8;
$colour-text1: #edfeff;
$colour-text2: #083039;
$colour-link: #75959c;
$colour-link-highlight: $colour-bg2;
$colour-section1-bg: $colour-text2;
$colour-section1-text: $colour-text1;
$colour-section2-bg: $colour-text1;
$colour-section2-text: $colour-text2;
$colour-success: #30d278;
$colour-warning: #bdc71b;
$colour-danger: #ea3869;

$width-small: 576px;
$width-medium: 768px;
$width-large: 992px;
$width-xlarge: 1200px;

$bg-height: 32.142rem;
$bg-width: 53.571rem;
